import React from "react"
import Layout from "../components/layout"
import "../styles/terms.scss"
export default function Terms() {
  return (
    <Layout>
        <div className="terms" >
            <h1>Användarvillkor för Utekolls app</h1>
            <p className="date" style={{color:'blue'}}>English version below</p>
            <p className="date">Senast uppdaterad 2023-06-01</p>

            <h2>Allmänt</h2>
            <p>Dessa användarvillkor (”Villkor”) är tillämpliga mellan dig som användare och leverantören av Utekoll, CO5 Konsulter AB, org.nr 559146-4762, (Utekoll, ”vi”, ”oss”) när du använder Utekolls applikation. (Gäller både webbaserade och mobilt installerade versioner.)</p>

            <p>För att använda Utekolls applikation måste du vara minst 18 år och/eller i övrigt vara behörig att ingå avtal samt acceptera att bli bunden av Villkoren. Alternativt, för minderåriga, ha målsmans tillåtelse att använda applikationen.</p>

            <p>Din integritet är viktig. Utekoll behandlar dina personuppgifter i enlighet med tillämplig lagstiftning och all behandling omgärdas av lämpliga tekniska och organisatoriska skyddsåtgärder.</p>

            <h2>Registrering</h2>
            <p>Vid registrering skapar du ett konto som innehåller din e-postadress, namn och ett valbart användarnamn. E-postmeddelanden kan skickas via vår samarbetspartner Sendinblue för att bekräfta registrering och för att återställa lösenord.</p>

            <h2>Tjänsten</h2>
            <p>I utekollappen kan du publicera aktivitetsrapporter innehållande text, bild, video. Dessa rapporter blir synliga i appen för alla som använder den. Utekollappen gör det möjligt att se andra rapportörers aktivitetsrapporter.</p>

            <p>Tjänstens syfte är att tillhandahålla en mötesplats för fritidsaktiviteter på sjöar och isar genom att låta användare dela med sig av sina egna upplevelser och ta del av andras upplevelser. Allt delat innehåll och övriga aktiviteter i tjänsten ska ske i positiv ton och god anda.</p>
            
            <h2> Utekolls ansvar och rättigheter</h2>
            <p>Utekolls app tillhandahålls ”som den är” utan någon uttrycklig eller underförstådd garanti. Utekoll garanterar ingen specifik tillgänglighet, kvalitet eller lämplighet för ett visst syfte och ger inga garantier för att appen alltid är fri från fel eller avbrott. Din användning av Utekolls app sker uteslutande på egen risk. I den utsträckning som det är tillåtet enligt tvingande svensk rätt ansvarar Utekoll inte för dina eller tredje parts direkta, indirekta eller andra skador av något slag.</p>

            <p>Utekolls app utvecklas kontinuerligt och Utekolls app kan därför komma att ändras löpande så som Utekoll anser nödvändigt och/eller lämpligt. Ändringar kan till exempel innefatta nya funktioner eller ny behandling av dina personuppgifter. Utekoll förbehåller sig därför rätten att använda de personuppgifter som du lämnar i Utekolls app för detta syfte. Uppgifter aggregeras och/eller anonymiseras där så är lämpligt och möjligt. Vidare förbehåller Utekoll sig rätten att ändra eller upphöra med tillhandahållandet av Utekolls app så som Utekoll finner det lämpligt, oavsett anledning.</p>

            <p>Utekoll har rätt att ta bort aktivitetsrapporter och övrigt användardata om det innehåller stötande, felaktigt eller på annat sätt opassande innehåll. Bedömningen av vad som är opassande görs av Utekoll.</p>

            <p>Utekoll har rätt att stänga av användare som rapporterat stötande, felaktigt eller på annat sätt opassande innehåll. Utekoll har också rätt att stänga av användare som använder tjänsten på ett sätt som inte följer våra riktlinjer.</p>
            
            <p>Utekoll har rätt att skicka information till användaren via email, men måste ge användaren möjlighet att välja bort sådan information och avbryta vidare mailkontakter.</p>

            <h2> Användarens ansvar och rättigheter</h2>
            <p>Utekoll tillhandahåller Utekolls app till dig som slutanvändare. Under förutsättning att du följer Villkoren ger Utekoll dig en personlig, begränsad, uppsägningsbar, icke-exklusiv och icke-överlåtningsbar rätt och licens att ladda ner, installera och använda Utekolls app på din enhet. Din användning av Utekolls app regleras i dessa Villkor.</p>

            <p>Ditt användarkonto är personligt och dina kontouppgifter ska hållas hemliga och får inte delas med andra. Du ansvarar för all användning av Utekolls app som sker genom ditt konto. Om du misstänker att någon obehörig person använder ditt konto ska du omgående anmäla detta till Utekoll.</p>

            <p>
            Du ansvarar för att de uppgifter som har lämnats vid registrering är korrekta och att e-postadressen är aktiv samt att det data och de inlägg du publicerar i Utekolls app inte är kränkande, nedsättande, skadligt, rasistiskt eller etniskt stötande, sexuellt explicit eller gör intrång i immateriell rättighet eller någons personliga integritet. Utekoll har rätt att ändra ditt användarnamn och radera dina inlägg/data om de, enligt Utekolls uppfattning, bryter mot Villkoren. Vidare ansvarar du för att ändra dina uppgifter hos Utekoll om tidigare lämnade uppgifter skulle bli inaktuella.
            </p>

            <p>Du kan när som helst välja att ta bort ditt konto via kontoavslutsfunktionen i appen eller genom att kontakta oss. Utekoll kommer då, i enlighet med gällande integritetspolicy, radera eller avidentifiera samtliga dina personuppgifter. Utekoll kan komma att spara information om dig, exempelvis om så krävs för uppfyllande av rättslig förpliktelse i enlighet med vår integritetspolicy.</p>

            <p>Dina rapporter tas inte automatiskt bort i samband med kontoavslut utan dessa tar du bort själv innan du avslutar kontot.</p>

            <h2>Immateriella rättigheter</h2> 
            <p>Alla immateriella rättigheter avseende Utekolls app tillhör Utekoll eller Utekolls licensgivare och samarbetspartners. Detta innefattar bland annat upphovsrätt, designrättigheter, varumärken, företagshemligheter, patent och know-how, oavsett om de förutsätter registrering eller inte.</p>

            <p>Användaren innehar de immateriella rättigheterna till sitt eget uppladdade bild och videomaterial. Användaren ger Utekoll den begränsade rätten att använda materialet i Utekolls app, på Utekolls webbsite, i Utekolls sociala kanaler och i marknadsföringskampanjer, men då endast i Utekolls kontext.</p>   

            <h2>Ändrade villkor</h2>
            <p>Utekoll förbehåller sig rätten att ändra dessa Villkor. Ändringar meddelas i Utekolls app. Genom att fortsätta använda Utekolls app accepterar du de nya villkoren. Om du inte accepterar de nya villkoren kan du alltid avinstallera Utekolls app.</p>

            <h2>Cookies</h2>
            <p>Cookies är textfiler som sparas automatiskt på din enhet och kan innehålla information om hur du använder Utekolls app. Cookies applicerar bara till webbversionen av Utekolls app. Du hittar mer info om cookies på www.aboutcookies.org or www.allaboutcookies.org. Där beskrivs också hur du avvaktiverar och rensar cookies, dock kan Utekolls app fungera sämre när cookies är avaktiverade.</p>

        </div>

        <div className="terms">
            <div style={{color:'blue', fontSize:'20px', fontWeight:'bold'}}>English Version</div>
        <h1>Terms of Use for the Utekoll App</h1>
<p className="date">Last updated on 2023-06-01</p>

<h2>General</h2>
<p>These terms of use ("Terms") apply between you as a user and the provider of Utekoll, CO5 Konsulter AB, registration number 559146-4762, (Utekoll, "we", "us") when you use the Utekoll application. (Applies to both web-based and mobile-installed versions.)</p>

<p>To use the Utekoll application, you must be at least 18 years old and/or otherwise have the authority to enter into agreements and accept being bound by the Terms. Alternatively, for minors, you must have the consent of a guardian to use the application.</p>

<p>Your privacy is important. Utekoll processes your personal data according to applicable legislation, and all processing is protected by appropriate technical and organizational measures.</p>

<h2>Registration</h2>
<p>When registering, you create an account with your email address, name, and an optional username. Email notifications may be sent via our partner Sendinblue to confirm registration and reset passwords.</p>

<h2>The Service</h2>
<p>In the Utekoll app, you can publish activity reports containing text, images, and videos. These reports become visible in the app to all users. The Utekoll app enables viewing activity reports from other users.</p>

<p>The purpose of the service is to provide a meeting place for leisure activities on lakes and ice by allowing users to share their own experiences and engage with others' experiences. All shared content and other activities in the service should be conducted in a positive tone and good spirit.</p>

<h2>Utekoll's Responsibilities and Rights</h2>
<p>The Utekoll app is provided "as is" without any express or implied warranty. Utekoll does not guarantee specific availability, quality, or suitability for a particular purpose and provides no guarantees that the app will always be free from errors or interruptions. Your use of the Utekoll app is entirely at your own risk. To the extent permitted by mandatory Swedish law, Utekoll is not liable for any direct, indirect, or other damages of any kind to you or third parties.</p>

<p>The Utekoll app is continuously developed and may change over time as Utekoll deems necessary and/or appropriate. Changes may include new features or new handling of your personal data. Therefore, Utekoll reserves the right to use the personal information you provide in the Utekoll app for this purpose. Data will be aggregated and/or anonymized where appropriate and possible. Furthermore, Utekoll reserves the right to change or discontinue the provision of the Utekoll app as deemed suitable by Utekoll, regardless of the reason.</p>

<p>Utekoll has the right to remove activity reports and other user data if they contain offensive, incorrect, or otherwise inappropriate content. The determination of what is inappropriate is made by Utekoll.</p>

<p>Utekoll has the right to suspend users who have reported offensive, incorrect, or otherwise inappropriate content. Utekoll also has the right to suspend users who use the service in a way that does not comply with our guidelines.</p>

<p>Utekoll has the right to send info to the user via email, but must give the user te option to opt out to such information and not receive further emails.</p>

<h2>User's Responsibilities and Rights</h2>
<p>Utekoll provides the Utekoll app to you as an end user. Provided you follow the Terms, Utekoll grants you a personal, limited, terminable, non-exclusive, and non-transferable right and license to download, install, and use the Utekoll app on your device. Your use of the Utekoll app is governed by these Terms.</p>

<p>Your user account is personal, and your account information should be kept confidential and not shared with others. You are responsible for all use of the Utekoll app through your account. If you suspect unauthorized use of your account, you should promptly report it to Utekoll.</p>

<p>You are responsible for ensuring that the information provided during registration is accurate, your email address is active, and the data and posts you publish in the Utekoll app are not offensive, derogatory, harmful, racist, or ethnically offensive, sexually explicit, or infringe on intellectual property rights or anyone's personal privacy. Utekoll has the right to change your username and delete your posts/data if, in Utekoll's view, they violate the Terms. Additionally, you are responsible for updating your information with Utekoll if previously provided information becomes outdated.</p>

<p>You can choose to delete your account at any time via the account closure function in the app or by contacting us. In accordance with the applicable privacy policy, Utekoll will then delete or de-identify all your personal information. Utekoll may retain information about you, for example, if required to fulfill legal obligations in accordance with our privacy policy.</p>

<p>Your reports are not automatically deleted when your account is closed; you must remove them yourself before closing your account.</p>

<h2>Intellectual Property Rights</h2>
<p>All intellectual property rights concerning the Utekoll app belong to Utekoll or Utekoll's licensors and partners. This includes copyright, design rights, trademarks, trade secrets, patents, and know-how, whether or not they require registration.</p>

<p>The user retains the intellectual property rights to their own uploaded images and video material. The user grants Utekoll the limited right to use the material in the Utekoll app, on Utekoll's website, in Utekoll's social media channels, and in marketing campaigns, but only within the context of Utekoll.</p>

<h2>Changes to the Terms</h2>
<p>Utekoll reserves the right to change these Terms. Changes will be communicated within the Utekoll app. By continuing to use the Utekoll app, you accept the new terms. If you do not agree with the new terms, you can always uninstall the Utekoll app.</p>

<h2>Cookies</h2>
<p>Cookies are text files that are automatically saved on your device and may contain information about how you use the Utekoll app. Cookies only apply to the web version of the Utekoll app. You can find more information about cookies on www.aboutcookies.org or www.allaboutcookies.org. These sites also describe how to disable and clear cookies, although the Utekoll app may function less effectively when cookies are disabled.</p>

        </div>
    </Layout>
  )
}